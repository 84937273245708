(function () {
    var header = {
        headerEl: document.querySelector('header'),
        body: document.body,
        row1: document.querySelector('.header--first-row'),
        row2: document.querySelector('.header--second-row'),
        row2_1st_col: document.querySelector('.header--second-row-first-col'),
        row2_2nd_col: document.querySelector('.header--second-row-second-col'),
        mainNavToggle: document.getElementById('mainNavToggle'),
        $window: $(window),
        vPort: 'screen-sm',
        isMobile: false,

        init: () => {
            if (header.row1 && header.row2) {
                header.handleStickyness();
                header.control();
            }
            header.setHeaderHeightVar();
        },
        control: () => {
            window.addEventListener('scroll', function () {
                header.handleStickyness();
            });

            $(document).on(header.vPort + '-on', function () {
                header.isMobile = true;
                header.handleStickyness();
            });

            $(document).on(header.vPort + '-off', function () {
                header.isMobile = false;
                header.handleStickyness();
                if (header.mainNavToggle?.parentElement?.classList.contains('menu-parent__opened')) {
                    header.body.classList.remove('overlay-active');
                    header.mainNavToggle.click();
                }
            });
        },
        handleStickyness: () => {
            if (header.isMobile) {
                if (window.pageYOffset > header.row2_1st_col?.offsetHeight) {
                    header.addMobileSticky();
                } else {
                    header.removeMobileSticky();
                }
            } else {
                header.removeMobileSticky();
            }
        },
        addMobileSticky: () => {
            header.row2_1st_col.style.marginBottom = header.row2_2nd_col?.offsetHeight + 'px';
            header.row2_2nd_col.classList.add('position-fixed');
        },
        removeMobileSticky: () => {
            header.row2_2nd_col.classList.remove('position-fixed');
            header.row2_1st_col.style.marginBottom = 0;
        },
        setHeaderHeightVar: () => {
            const headerSize = header.headerEl.offsetHeight + 'px';
            document.documentElement.style.setProperty('--header-height', headerSize);
        },
    };

    UX.header = header;
})();
