(function () {
    var stringsTruncater = {
        truncatorTarget: document.querySelectorAll('.js--truncate-string'),
        truncatorCharsLength: 13, // default and can be changed by using data-truncate-chars
        truncateSuffix: '...',

        init: function () {
            stringsTruncater.truncate();
        },

        truncate: function () {
            this.truncatorTarget.forEach(elem => {
                var targetString = elem.innerText;
                var truncateCharsLength = elem.getAttribute('data-truncate-chars') || this.truncatorCharsLength;
                var truncatedValue = targetString.substring(0, truncateCharsLength) + this.truncateSuffix;

                if (targetString.length > truncateCharsLength) {
                    elem.innerText = truncatedValue;
                }
            });
        },
    };

    UX.stringsTruncater = stringsTruncater;
})();
